<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" :text-color="'primary'" />
      <v-spacer></v-spacer>
      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card class="box box-primary">
      <!-- <v-system-bar color="indigo darken-2" dark>
        <v-spacer></v-spacer>

        <v-icon>mdi-window-minimize</v-icon>

        <v-icon>mdi-window-maximize</v-icon>

        <v-icon>mdi-close</v-icon>
      </v-system-bar> -->
      <!-- 
      <v-toolbar color="primary" dark pa-2>
        <page-title :title="title" :text-color="'#fff'" />
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Discover</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar> -->
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>

        <template v-slot:top>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-select
                  label="Filter by  level"
                  v-model="params.level"
                  @change="init"
                  :items="adminLevels"
                  :item-text="'name'"
                  :item-value="'id'"
                  class
                  clearable
                  flat
                  hide-details
                  dense
                  prepend-icon="mdi-magnify"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-select
                  label="Filter by level Type"
                  v-model="params.school_type_id"
                  @change="init"
                  :items="schoolLevels"
                  :item-text="'name'"
                  :item-value="'id'"
                  class
                  clearable
                  flat
                  hide-details
                  dense
                  prepend-icon="mdi-magnify"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="3" lg="3">
                <v-select
                  label="Filter by Ownership"
                  v-model="params.ownership_id"
                  @change="init"
                  :items="schoolOwnership"
                  :item-text="'name'"
                  :item-value="'id'"
                  class
                  clearable
                  flat
                  hide-details
                  dense
                  prepend-icon="mdi-magnify"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="3" lg="3">
                <v-text-field
                  @change.native="init"
                  v-model="search"
                  class
                  clearable
                  flat
                  hide-details
                  dense
                  prepend-icon="mdi-magnify"
                  outlined
                  label="Enter search String"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
        </template>
      </v-data-table>
      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>

    <LocationDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :filterLevels="filterLevels"
      :levels="levels"
      :adminLevels="adminLevels"
      :schoolLevels="schoolLevels"
      :schoolOwnership="schoolOwnership"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import LocationDialog from "./Forms/LocationDialog.vue";
import { get, create, update, remove } from "./services";
import { get as adminLevel } from "./../levels/services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    LocationDialog,
  },
  name: "Location",
  data() {
    return {
      title: "Manage Locations",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      search: null,
      params: {},
      item: null,
      items: [],
      levels: [],
      schoolLevels: [],
      adminLevels: [],
      schoolOwnership: [],

      search: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Level",
          align: "start",
          sortable: false,
          value: "level.name",
          class: "primary--text",
        },

        {
          text: "Reg#",
          align:
            this.params.level == 5 || this.user?.location?.level?.id === 5
              ? "start"
              : " d-none",
          sortable: false,
          value: "reg_no",
          class: "primary--text",
        },

        {
          text: "NECTA #",
          align:
            this.params.level == 5 || this.user?.location?.level?.id === 5
              ? "start"
              : " d-none",
          sortable: false,
          value: "necta_code",
          class: "primary--text",
        },
        {
          text: "Ownership",
          align:
            this.params.level == 5 || this.user?.location?.level?.id === 5
              ? "start"
              : " d-none",
          sortable: false,
          value: "ownership.name",
          class: "primary--text",
        },

        {
          text: "Address",
          align:
            this.params.level == 5 || this.user?.location?.level?.id === 5
              ? "start"
              : " d-none",
          sortable: false,
          value: "address",
          class: "primary--text",
        },
        {
          text: "Sectors",
          align: "start",
          sortable: false,
          value: "sector",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ];
    },
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
  },

  methods: {
    init(data, key = null) {
      let params = {
        ...data,
        ...this.params,
        search: this.search ? this.search : null,
      };

      this.loading = true;
      get({
        ...params,
      })
        .then((response) => {
          this.loading = false;
          // console.log('resp', response.data[0].total)
          this.params.total = response.data[0].total;

          this.items = response.data[0]
            ? response.data[0].data
                .map((option) => ({
                  ...option,
                  sector: option.sectors.map((n) => n.name),
                }))

                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    searchRoles(data) {
      this.searchOptions(data);
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    getLevel() {
      this.$mixins.fetchByCategory("EDUCATION_LEVELS").then((response) => {
        this.levels = response;
      });
    },

    getSchoolOwnership() {
      this.$mixins.fetchByCategory("SCHOOL_OWNERSHIP").then((response) => {
        this.schoolOwnership = response;
      });
    },

    getSchoolLevels() {
      this.$mixins.fetchByCategory("EDUCATION_LEVELS").then((response) => {
        this.schoolLevels = response;
      });
    },

    getAdminLevels() {
      adminLevel().then((response) => {
        this.adminLevels = response.data[0].data;
      });
    },
    filterLevels(data) {},
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getLevel();
    this.getAdminLevels();
    this.getSchoolLevels();
    this.getSchoolOwnership();
  },
};
</script>

<style scoped>
.box.box-primary {
  border-top-color: #3c8dbc;
}
</style>
