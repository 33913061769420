<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Location</span>
          </v-toolbar-title>
        </v-toolbar>

        <perfect-scrollbar>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-select
                    label="Select Administation Area's Level"
                    :items="adminLevels"
                    :item-text="'name'"
                    v-model="formData.level"
                    return-object
                    required
                    v-uppercase
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  :lg="isSchool ? 6 : 12"
                  :md="isSchool ? 6 : 12"
                  sm="12"
                  v-if="isSchool"
                >
                  <v-select
                    label="Select School Ownership"
                    :items="schoolOwnership"
                    :item-text="'name'"
                    v-model="formData.ownership"
                    return-object
                    required
                    v-uppercase
                    :rules="isValid.name"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  :lg="isSchool ? 6 : 12"
                  :md="isSchool ? 6 : 12"
                  sm="12"
                >
                  <v-text-field
                    label="Add Name"
                    v-model="formData.name"
                    required
                    :rules="isValid.name"
                    v-uppercase
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="isSchool">
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    label="SCHOOL REGISTRATION NUMBER eg. S.340"
                    v-model.trim="formData.reg_no"
                    :rules="[
                      isValidated.required,
                      isValidated.existRegNoMessage,
                    ]"
                    required
                    :loading="validateReg"
                    @change.native="validateSchoolNumber($event.target.value)"
                    :messages="existRegNoMessage"
                    v-uppercase
                    v-mask="mask"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    label="NECTA REGISTRATION NUMBER eg S.30"
                    v-model.trim="formData.necta_code"
                    :loading="validateNecta"
                    :rules="[
                      isValidated.required,
                      isValidated.existNectaMessage,
                    ]"
                    :messages="existNectaNoMessage"
                    required
                    @change.native="validateNectaCode($event.target.value)"
                    v-uppercase
                    v-mask="mask"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-select
                    label="Select school Type"
                    :items="schoolLevels"
                    :item-text="'name'"
                    v-model="formData.schoolType"
                    return-object
                    required
                    v-uppercase
                    :rules="[isValidated.required]"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row v-if="isSchool">
                <v-col cols="12" lg="3" md="3" sm="12">
                  <v-text-field
                    label="Distance from LGA"
                    v-model="formData.distance_lga"
                    required
                    :rules="isValid.name"
                    v-mask="'###'"
                    v-uppercase
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="2" md="2" sm="12">
                  <v-text-field
                    label="Latitude"
                    v-model="formData.latitude"
                    required
                    v-mask="'#######.##'"
                    v-uppercase
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="2" md="2" sm="12">
                  <v-text-field
                    label="Longitude"
                    v-model="formData.longitude"
                    required
                    v-mask="'#######.##'"
                    v-uppercase
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="4" md="4" sm="12">
                  <v-textarea
                    label="School  Address"
                    v-model="formData.address"
                    @input="formData.address = $event.toUpperCase()"
                    rows="2"
                    prepend-icon="mdi-comment"
                    :rules="[isValidated.required]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <DualSelects
                    :items="levels"
                    :title="'Select School Sectors'"
                    :label="'Filter Sectors...'"
                    :columnName="'name'"
                    :filter-function="filter"
                    v-model="formData.sectors"
                    :is-open="dialog"
                    v-if="isSchool"
                  />
                </v-col>
              </v-row>

              <v-row v-if="user.location.level.id !== 5">
                <v-col cols="12" sm="12" md="12">
                  <eExamTree
                    :setActive="setActive"
                    :selected="selected"
                    :location-title="'Parent Administation Area:'"
                    :parent-data="formData.parent"
                    @parent-data="formData.parent = $event"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </perfect-scrollbar>
        <!-- <small>*indicates required field</small> -->
        <!-- <pre>
         isSchool: {{ isSchool }}

        isValidLevelLocation: {{ isValidLevelLocation }} isValid: {{ valid }}
        </pre>
        <pre> level id {{ user.location.level }}</pre>
        <pre>formdata id {{ formData.level }}</pre>
        <pre>Location {{ formData.parent }}</pre> -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid && isValidLevelLocation"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
import { get as adminAreas } from "../../school-profile/services";
export default {
  props: [
    "closeDialog",
    "dialog",
    "filterLevels",
    "isEditing",
    "formData",
    "save",
    "loading",
    "levels",
    "adminLevels",
    "schoolLevels",
    "schoolOwnership",
  ],
  name: "LocationDialog",
  data: () => ({
    valid: true,
    isValid: {
      name: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
    },
    existRegNoMessage: null,
    existNectaNoMessage: null,
    validateNecta: false,
    validateReg: false,

    isValidated: {
      required: (value) => !!value || "Required.",

      existRegNoMessage: () => true || this.existRegNoMessage,
      existNectaMessage: () => true || this.existNectaNoMessage,
    },
    location: {},

    mask: ["S.", /\d/, /\d/, /\d/, /\d/],
  }),
  methods: {
    setActive(selection) {
      console.log(selection);
      this.location = selection.length > 0 ? selection[0] : { name: null };
      this.formData.parent = selection[0];
    },

    filter(data) {
      this.filterLevels(data);
    },
    add() {
      let data = { ...this.formData };
      data.sectors = this.formData?.sectors?.map((item) => item.id).join(", ");
      data.sectorIds = this.formData?.sectors?.map((item) => item.id);

      data.code = data.code
        ? data.code
        : data.name
            .trim()
            .split(" ")
            .join("_")
            .toUpperCase();
      // console.log("data", data);
      this.save(data);
    },

    close() {
      this.closeDialog();
    },

    validateSchoolNumber(data) {
      this.validateReg = true;
      let payload = {
        reg_no: data.toUpperCase(),
      };

      adminAreas(payload).then((response) => {
        this.validateReg = false;

        if (response.data[0].data.length > 0) {
          this.isValid.existRegNoMessage = false;
          this.existRegNoMessage = "School  Registration number already taken";
        } else {
          this.isValid.existRegNoMessage = true;
          this.existRegNoMessage = "";
        }
      });

      // console.log("validating now .....");
    },

    validateNectaCode(data) {
      this.validateNecta = true;
      let payload = {
        necta_code: data.toUpperCase(),
      };

      adminAreas(payload).then((response) => {
        this.validateNecta = false;
        if (response.data[0].data.length > 0) {
          this.isValid.existNectaMessage = false;
          this.existNectaNoMessage = "Necta Number already taken";
        } else {
          this.isValid.existNectaMessage = true;
          this.existNectaNoMessage = "";
        }
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    title() {
      return this.formData.id ? "Update" : "Create";
    },

    selected() {
      return this.formData &&
        this.formData.parent &&
        Object.keys(this.location).length === 0
        ? this.formData.parent
        : this.location;
    },

    isSchool() {
      return this.formData?.level?.id === 5;
    },

    isValidLevelLocation() {
      if (this.formData?.level?.id === this.location?.level?.id) {
        return true;
      } else if (this.formData?.level?.id - 1 === this.location?.level?.id) {
        return true;
      } else if (
        this.user?.location?.level?.id === 5 &&
        this.user?.location?.level?.id === this.formData?.level?.id
      ) {
        return true;
      } else if (this.formData?.parent?.id === 392) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.ps {
  height: 600px;
}
</style>
